import { Button, Form } from "react-bootstrap";
import { DropdownCloseIcon, FileListIcon } from "../components/Icons";
import { isEmpty } from "lodash";

// GA - Added View Only

export function FilesAttachments({
  files = [],
  setFiles,
  url,
  deleteImage,
  viewOnly = false,
  colWidth = "6",
  isLoading = false,
  upload,
}) {
  try {
    function isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }

    return (
      <div className="container">
        <div className="row">
          {files?.map((el, index) => (
            <div
              key={index}
              className={`d-flex align-items-center col-${colWidth} mb-1`}
            >
              {el?.fileId && url ? (
                <a
                  className="text-primary"
                  href={`${url}/${el.systemFileName}`}
                  target="_blank"
                >
                  {isImage(el.systemFileName) ? (
                    <img
                      src={`${url}/${el.systemFileName}`}
                      className="img-fluid"
                      alt={el.systemFileName}
                      /*   alt={el?.fileId} */
                      width={100}
                    />
                  ) : (
                    <span>📄{el.name}</span>
                  )}
                </a>
              ) : (
                <>
                  {isImage(el.name) ? (
                    <img
                      src={URL.createObjectURL(el)}
                      /* alt={`{new${index}}`} */
                      alt={el?.name}
                      className="img-fluid"
                      width={100}
                    />
                  ) : (
                    <span>📄{el.name}</span>
                  )}
                </>
              )}

              {!viewOnly && (
                <Button
                  variant=""
                  size="sm"
                  onClick={() => {
                    if (deleteImage && el?.fileId) {
                      deleteImage(index);
                    } else {
                      setFiles(files.filter((el, i) => i !== index));
                    }
                  }}
                >
                  <DropdownCloseIcon width={16} />
                </Button>
              )}
              <hr className="m-0" />
            </div>
          ))}
        </div>

        <div className="d-flex gap-3">
          {!viewOnly && (
            <Form.Label className="btn btn-light-blue">
              <span>+ Add Files </span>
              <Form.Control
                type="file"
                className="d-none"
                multiple
                onChange={(e) => setFiles([...files, ...e.target.files])}
              />
            </Form.Label>
          )}
          {upload && !isEmpty(files) && files.some((el) => !el?.fileId) ? (
            <Button size="sm" className="fs-8 mb-2" onClick={() => upload()}>
              Upload
            </Button>
          ) : null}
        </div>

        {isLoading && <i>Please wait...</i>}
      </div>
    );
  } catch (err) {
    console.log(err);
    return "";
  }
}

import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  getDataForEdit,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  LinkVariantIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase, mapValues, keyBy } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";

import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import {
  useEffectOnce,
  useIsAdmin,
  useIsCashier,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TransactionsTable from "../utils/TransactionsTable";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./../utils/ModalLoader";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import {
  Field,
  FieldArray,
  useFormik,
  FormikProvider,
  ErrorMessage,
} from "formik";
import CachedIcon from "mdi-react/CachedIcon";

export default function ReplaceItem() {
  useEffectOnce(() => scrollToTop());
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [paymentMeduims, setPaymentMeduims] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const [selectedSale, setSelectedSale] = useState({});

  const replaceSale = async (payload) => {
    let response = await fetch(`${backendUrl}/api/sales/replace-item`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const replaceSaleMutation = useMutation((payload) => replaceSale(payload), {
    onSuccess: ({ data, message }) => {
      toast.success(message);

      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const [TransactionID, setTransactionID] = useState("");
  useEffectOnce(() => {
    if (location.search) {
      const queryParams = queryString.parse(location.search);
      setTransactionID(queryParams.TransactionID);
      navigate(location.pathname, { replace: true });
    }
  });
  const formik = useFormik({
    initialValues: {
      date: moment(),
      Remark: "",
      deletePayment: false,
      sales: [
        /* { Bar_Code: "", showItemsPopover: false } */
      ],
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        replaceSaleMutation.mutate({
          TransactionID,
          ...values,
          customer: data.customer,
          isCustomerChanged:
            values.sales[0].Cust_ID !== selectedCustomer.Cust_ID,
          selectedCustomer,
        });
      }
    },
    onReset: () => {},
  });

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(null);
  };

  const handleSelectedTransaction = (transaction) => {
    setTransactionID(transaction.TransactionID);
    setShowTransactionPopover(false);

    // fetch sales data
  };

  const getSales = async ({ TransactionID }) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/transaction/sales/${TransactionID}?withTransaction=true&withLedgerRecords=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (data?.customer) {
      setSelectedCustomer(data?.customer);
    }
    if (data.sales) {
      formik.setFieldValue(
        "sales",
        data.sales.map((el) => ({
          ...el,
          originalSalesValues: el,
          showItemsPopover: false,
        }))
      );
    }
    return data;
  };

  const transaction = useMemo(() => cloneDeep({ TransactionID }), [
    TransactionID,
  ]);

  const { data = {}, refetch } = useQuery(
    [queryActions.GET_SALES_BY_TRANSACTION_ID, transaction],
    () => getSales(transaction),
    {
      keepPreviousData: false,
    }
  );

  //  const replace = (sale) => {};

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="Replace Item"
        description=""
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-md-flex content-holder rounded">
          <section className="item-details">
            <div>
              <header className="h-auto gap-3">
                <Form.Group className="mt-4 mb-3 col-md-6">
                  <InputGroup>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Transaction ID"
                      name="TransactionID"
                      value={TransactionID}
                      onChange={(e) => setTransactionID(e.target.value)}
                    />

                    <Popover
                      isOpen={showTransactionPopover}
                      onClickOutside={() => setShowTransactionPopover(false)}
                      content={() => (
                        <TransactionsTable
                          handleSelectedTransaction={handleSelectedTransaction}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() =>
                          setShowTransactionPopover(!showTransactionPopover)
                        }
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Bar_Code}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant=""
                  className="text-primary"
                  onClick={() => refetch()}
                >
                  <CachedIcon />
                </Button>
              </header>

              <div className="selected-data-area">
                <FormikProvider value={formik}>
                  <Form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                  >
                    <div className="table-holder">
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table  text-nowrap"
                      >
                        <thead>
                          <tr>
                            {generalSettings?.itemsRequiresSerialNumber ? (
                              <>
                                <th>Serial Number</th>
                              </>
                            ) : (
                              <>
                                <th>Item Code</th>
                              </>
                            )}
                            <th>Item Name</th>
                            <th>Size/Desc</th>
                            <th>Price Sold</th>
                            <th>Quantity</th>
                            <th>Subtotal</th>
                            <th>Discount</th>
                            <th>Product name</th>
                            <th>Unit Price</th>
                            <th>Profit</th>
                            <th>...</th>
                            <th>...</th>
                            <th>Overwrite officer</th>
                            <th>Unit Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name="splitPaymentCardArray"
                            render={(arrayHelpers) =>
                              formik.values.sales.map((el, index) => (
                                <tr key={index}>
                                  <td>
                                    <InputGroup className="flex-nowrap">
                                      <Form.Control
                                        name={`sales[${index}].Bar_Code`}
                                        placeholder="Enter Item Code"
                                        value={
                                          formik.values.sales[index]
                                            ?.SerialNumberItems ||
                                          formik.values.sales[index].Bar_Code
                                        }
                                        readOnly
                                      />

                                      <Popover
                                        isOpen={
                                          formik.values.sales[index]
                                            .showItemsPopover
                                        }
                                        onClickOutside={() => {
                                          formik.setFieldValue(
                                            `sales[${index}].showItemsPopover`,
                                            false
                                          );
                                        }}
                                        content={() => (
                                          <ItemsTable
                                            serialNumbers={
                                              generalSettings.itemsRequiresSerialNumber
                                            }
                                            handleSelectedItem={(item) => {
                                              // check for serial number
                                              if (
                                                generalSettings.itemsRequiresSerialNumber
                                              ) {
                                                formik.setFieldValue(
                                                  `sales[${index}].SerialNumberItems`,
                                                  item?.serialNum
                                                );
                                                // Get item details
                                                item = item.Items_Serial;
                                              }

                                              formik.setFieldValue(
                                                `sales[${index}].Bar_Code`,
                                                item?.Bar_Code
                                              );
                                              formik.setFieldValue(
                                                `sales[${index}].Item_Name`,
                                                item?.Item_Name
                                              );
                                              formik.setFieldValue(
                                                `sales[${index}].Product_Name`,
                                                item?.ProductName
                                              );
                                              formik.setFieldValue(
                                                `sales[${index}].Serial_Number`,
                                                item?.Item_Desc
                                              );
                                              formik.setFieldValue(
                                                `sales[${index}].showItemsPopover`,
                                                false
                                              );
                                            }}
                                          />
                                        )}
                                        position="bottom"
                                      >
                                        <InputGroup.Text
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `sales[${index}.showItemsPopover`,
                                              !formik.values.sales[index]
                                                .showItemsPopover
                                            );
                                          }}
                                        >
                                          <MagnifyIcon />
                                        </InputGroup.Text>
                                      </Popover>
                                    </InputGroup>
                                  </td>
                                  <td>{el.Item_Name}</td>
                                  <td>{el?.Serial_Number || "..."}</td>
                                  <td>
                                    {currency(el.PriceSold, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {qtyFormatToString(
                                      qtyFormat(
                                        el?.originalSalesValues.QTY,
                                        el?.originalSalesValues.Serial_Number,
                                        itemMeasurements
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {currency(el.SubTotal, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {currency(el.Discount, {
                                      symbol: "",
                                    }).format()}
                                  </td>

                                  <td>{el.Product_Name || el.ProductName}</td>
                                  <td>
                                    {currency(el.Unit_Price, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>
                                    {currency(el.Profit, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                  <td>{el.Warranty}</td>
                                  <td>{el.Warrant_Duration}</td>
                                  <td>{"..."}</td>

                                  <td>
                                    {currency(el.UnitCost, {
                                      symbol: "",
                                    }).format()}
                                  </td>
                                </tr>
                              ))
                            }
                          />
                        </tbody>
                      </Table>
                    </div>
                  </Form>
                </FormikProvider>

                {/*  No item  */}
                {isEmpty(formik.values.sales) ? (
                  <div className="no-item my-4">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">No Items Found</h2>
                      <p>...</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
          <section className="customer">
            <h2>Business Name</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <UserSolidIcon />
              </div>
              {selectedCustomer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{selectedCustomer?.LastName}</h3>
                    <p>{selectedCustomer.Cust_ID}</p>
                  </div>

                  <div>
                    {!formik.values.pendingTransaction && (
                      <Dropdown style={{ margin: 0 }}>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-light-blue text-primary"
                          bsPrefix="change"
                        >
                          Change
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className=""
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCustomerSelectorModal(true)}
                          >
                            Select Customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCreateNewCustomerModal(true)}
                          >
                            Create New Customer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>Select customer or create new customer.</p>

                  <div className="d-grid mt-4">
                    <Button
                      onClick={() => setShowCustomerSelectorModal(true)}
                      variant="outline-primary"
                    >
                      Select Customer
                    </Button>
                    <Button
                      onClick={() => setShowCreateNewCustomerModal(true)}
                      variant="outline-primary"
                    >
                      + Create New Customer
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <section>
              <h2 className="px-2">Payment Status</h2>
              <Table borderless className="mb-2">
                <tbody>
                  <tr>
                    <td className="fw-bold">Amount Due:</td>
                    <td>
                      {currency(data?.transaction?.Amount_Due, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Amount Paid:</td>
                    <td>
                      {currency(data?.transaction?.Amount_Paid, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Balance:</td>
                    <td>
                      {currency(data?.transaction?.Balance, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Prepared By:</td>
                    <td>{data?.transaction?.Username}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Date:</td>
                    <td>
                      {data?.transaction?.Trans_Date
                        ? format(
                            new Date(data?.transaction?.Trans_Date),
                            "dd MMM yyyy"
                          )
                        : "..."}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </section>

            <section className="buttons">
              {/* isAdmin || (appSettings.isGigc && isCashier) */ true ? (
                <>
                  <Button
                    type="button"
                    variant="outline-primary"
                    // className="border-0"
                    onClick={() => discard()}
                  >
                    Refresh
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={() => formik.submitForm()}
                  >
                    Save Changes
                  </Button>
                </>
              ) : null}
            </section>
          </section>
        </div>
      </div>

      <ModalLoader show={replaceSaleMutation.isLoading} />
      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={handleSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={false}
        />
      )}
      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </main>
  );
}

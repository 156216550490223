import { Form, InputGroup, Button, Dropdown } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Popover } from "react-tiny-popover";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { reportActions } from "../../utils/reactQueryActions";
import {
  ExportIcon,
  FilterTwoIcon,
  FilterCollapseIcon,
  AddCircleIcon,
  CirclesFourIcon,
  TagSimpleIcon,
  DotSpinner,
  SearchIcon,
  NoSelectedItemIcon,
  PDFIcon,
  ExcelIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import { IssueItemModal } from "../modals/IssueItemModal";
import ViewInventryTransit from "../modals/ViewInventryTransit";
import { fetchActionsUtil } from "../../utils/helpers";
import { CustomInput } from "../utils/CustomInput";
import ItemsTable from "../utils/ItemsTable";
import ConfirmDialog from "../ConfirmDialogue";
import TableComponent from "../TableComponent";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { SerialIssueModal } from "../modals/SerialIssuerModal";
import { useStoreActions, useStoreState } from "easy-peasy";
import MagnifyIcon from "mdi-react/MagnifyIcon";

const allStatus = [
  "All",
  "Rejected",
  "Partly Received",
  "Received All",
  "Retured",
  null,
];

export function ItemIssuer() {
  useScrollTop();
  const initialFilterParams = {
    barCode: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    serialNumbers: "",
  };

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);
  const [issueItemModalShow, setIssueItem] = useState(false);
  const [viewItemModalShow, setViewItem] = useState(false);
  const [allIsuesItems, setAllIsuesItems] = useState([]);
  const [selectCompany, setSelectCompany] = useState("All");
  const [loadingId, setLoadingId] = useState();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showStatus, setShowStatus] = useState("All");
  const [excelData, setExcelData] = useState([]);

  const [showSerialPopover, setShowSerialPopover] = useState(false);

  const generalSettings = useStoreState((state) => state.generalSettings);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchSetUpData = async (queryParams) => {
    let response = await fetch(
      `${backendUrl}/api/itemissuer/setup?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    setAllIsuesItems(() => data.issuedItems);

    data.staff = data.staffs.map((el) => ({
      value: el.Name,
      label: el.Name,
    }));

    data.requesters = [
      "All",
      ...new Set(data.issuedItems.map((el) => el.Requester)),
    ].map((el) => ({
      value: el,
      label: el,
    }));
    console.log(data);

    return data;
  };

  const {
    data = { countItemsIssueLog: 0, staff: [], requesters: [] },
    refetch,
  } = useQuery(
    ["ISSUER_WAREHOUSE_ITEMS", queryParams],
    () => fetchSetUpData(queryParams),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  const returnItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/pending/return`, "POST", "", payload),
    {
      onSuccess: ({ message, data }) => {
        queryClient.setQueryData(
          [reportActions.SALE_BY_INVOICE, queryParams],
          (oldData) => {
            console.log("oldData => ", oldData.issuedItems);
            setAllIsuesItems(oldData.issuedItems);
            oldData = data;
            return oldData;
          }
        );
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );
  const handleSelectedItem = (el) => {
    setFilterParams({
      ...filterParams,
      barCode: el.Bar_Code,
    });
    setShowItemsPopover(false);
  };

  const handleSerialItem = (el) => {
    setFilterParams({
      ...filterParams,
      serialNumbers: el?.serialNum,
    });
    setShowSerialPopover(false);
  };

  const onSelect = (value) => {
    setSelectCompany(value);
    console.log(value);
    const filter = data?.issuedItems.filter((d) => d.Requester === value);

    if (value === "All") {
      setAllIsuesItems(() => data.issuedItems);
    } else {
      setAllIsuesItems(() => filter);
    }
  };

  const onStatusSelect = (value) => {
    setShowStatus(value);
    console.log(value);

    const filter =
      value !== "Pending"
        ? data?.issuedItems.filter((d) => d.Status === value)
        : data?.issuedItems.filter((d) => d.Status === null);

    if (value === "All") {
      setAllIsuesItems(() => data.issuedItems);
    } else {
      setAllIsuesItems(() => filter);
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const returnItem = async (index, el) => {
    setLoadingId(index);

    if (
      await ConfirmDialog({
        title: "Return Item",
        description:
          "Are you sure you want to return this Item to your inventory",
      })
    ) {
      returnItemMutation.mutate(el);
    }
  };

  const displayTableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item as="div">
                <a
                  href={`${backendUrl}/api/invoice/pdf/issue-invoice/${
                    el.Trans_ID
                  }`}
                  target="blank"
                >
                  View Receipt
                </a>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{el?.Bar_Code}</td>
        <td>
          {el?.Serial_Number
            ? el?.Serial_Number
            : JSON.parse(el?.serialNumbers)?.map((sl) => (
                <span className="mx-2">{sl?.serialNum}</span>
              ))}
        </td>
        <td>{el?.Item_Name}</td>
        <td className="text-center">{el?.Quantity}</td>
        <td className="text-nowrap">
          {el?.Requester}
          <span className="px-2">- {el?.RequesterName}</span>
        </td>
        <td className="text-center">{el?.QuantityFromReciever}</td>
        <td>
          {`${format(new Date(el?.Date_Log), "yyyy-MM-dd")} ${
            el?.IssueTime?.includes("Z")
              ? format(new Date(el.IssueTime), "HH:mm:ss a")
              : el?.IssueTime
          }`}
        </td>
        <td>
          <div
            className={`px-2 ${
              el.Status === "Rejected"
                ? "view_status_reject"
                : el.Status === "Partly Received"
                ? "view_status_partly"
                : el.Status === "Received All"
                ? "view_status_all"
                : el.Status === "Retured"
                ? "view_status_returned"
                : el.Status === null
                ? "view_status_pending"
                : ""
            }`}
          >
            {el.Status !== null ? el.Status : "Pending"}
          </div>
        </td>
        <td>{el?.Comment}</td>
        {el.Status === "Rejected" ? (
          <td>
            {" "}
            {returnItemMutation.isLoading && loadingId === index ? (
              <div>
                <DotSpinner />
                <div id="svg-para" />
              </div>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="bg-white border-0"
                  bsPrefix="print more"
                >
                  <DotsVerticalIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="dropdown-with-icons"
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => returnItem(index, el)}
                  >
                    <TagSimpleIcon className="text-light" />
                    Return to Stock
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="button"
                    // onClick={() => handleDeleteItem(el)}
                  >
                    <CirclesFourIcon />
                    Move to Damages
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </td>
        ) : (
          <td />
        )}
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Bar Code</th>
          <th>Serial Number</th>
          <th>Item Name</th>
          <th>Qty Issued</th>
          <th>Reciever</th>
          <th>Reciever Qty</th>
          <th>Date</th>
          <th>Status</th>
          <th>Comment</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/itemissuer/setup?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = exData.data.issuedItems.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
      IssueTime: row?.IssueTime?.includes("Z")
        ? format(new Date(row.IssueTime), "HH:mm:ss a")
        : row?.IssueTime,
    }));

    exData = exData.map((d) => [
      d.Bar_Code,
      d.Serial_Number,
      d.Item_Name,
      d.Quantity,
      d.Requester,
      `${d.RequesterName} - ${d.QuantityFromReciever}`,
      `${d.Date_Log} ${d.IssueTime}`,
      d.Status !== null ? d.Status : "Pending",
      d.Comment,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Item Issuser Report"],
      [date],
      [""],
      [
        "Bar Code",
        "Serial Number",
        "Item Name",
        "Qty Issued",
        "Reciever",
        "Reciever Qty",
        "Date",
        "Status",
        "Comment",
      ],
      ...exData,
      [""],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Bar Code</Form.Label>
                      <div className="input-group mb-3">
                        <InputGroup>
                          <Form.Control
                            name="barCode"
                            placeholder="Enter Bar Code"
                            value={filterParams?.barCode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSelectedItem}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </div>
                    </Form.Group>

                    {generalSettings.itemsRequiresSerialNumber && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Serial number</Form.Label>
                        <div className="input-group mb-3">
                          <Popover
                            isOpen={showSerialPopover}
                            onClickOutside={() => setShowSerialPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSerialItem}
                                serialNumbers
                              />
                            )}
                            position="bottom"
                          >
                            <>
                              <CustomInput
                                className="p-cursor form-control"
                                name="serialNumbers"
                                placeholder="serial number"
                                typeOfInput="text"
                                value={filterParams?.serialNumbers}
                                disabled
                                onChange={(e) => handleFilterParamsChange(e)}
                              />

                              <span
                                className="input-group-text border p-cursor"
                                id="basic-addon1"
                                onClick={() =>
                                  setShowSerialPopover(!showSerialPopover)
                                }
                              >
                                <SearchIcon />
                              </span>
                            </>
                          </Popover>
                        </div>
                      </Form.Group>
                    )}

                    <hr className="mt-3 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        defaultChecked={JSON.parse(
                          filterParams?.enableDateRange || false
                        )}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams?.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="content-body">
              <header className="text-nowrap">
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Issued Item History
                  <button
                    title="Refresh"
                    onClick={() => {
                      setSelectCompany("All");
                      refetch();
                    }}
                    className="btn text-primary mx-3"
                  >
                    <CachedIcon />
                  </button>
                  <span>Select Customer : </span>
                  <div className="mx-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => onSelect(e.target.value)}
                      value={selectCompany}
                    >
                      {data.requesters.map(({ value, label }, i) => (
                        <option value={value}>{label}</option>
                      ))}
                    </select>
                  </div>
                  <span>Select Status : </span>
                  <div className="mx-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => onStatusSelect(e.target.value)}
                      value={showStatus}
                    >
                      {allStatus.map((value, i) => (
                        <option key={i} value={value}>
                          {value === null ? "Pending" : value}
                        </option>
                      ))}
                    </select>
                  </div>
                </h1>
                <div className="actions">
                  <div
                    className="btn btn-primary"
                    onClick={() => setIssueItem(true)}
                  >
                    Issue Item{" "}
                    <AddCircleIcon color="#FFFFFF" width="" height="" />
                  </div>

                  <CSVLink
                    className="btn print d-none"
                    filename={`Item Issuser Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/itemissuer/pdf/item-issuer?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={allIsuesItems}
                  tableDataRowFunction={displayTableData}
                  className="product-table text-nowrap"
                />
              </div>

              {allIsuesItems?.length < 1 && (
                <div className="d-flex justify-content-center">
                  <div className="info text-center">
                    <NoSelectedItemIcon />
                    <h2 className="my-2">No Item Available</h2>
                    <p />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(
                    data.countItemsIssueLog / queryParams.limit
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={parseInt(queryParams.page) - 1}
                />
              </div>
            </div>
          </div>
        </div>
        {issueItemModalShow && !generalSettings.itemsRequiresSerialNumber && (
          <IssueItemModal
            show={issueItemModalShow}
            onHide={() => setIssueItem(false)}
            staffs={data.staff}
            refetchMain={refetch}
          />
        )}

        {issueItemModalShow && generalSettings.itemsRequiresSerialNumber && (
          <SerialIssueModal
            show={issueItemModalShow}
            onHide={() => setIssueItem(false)}
            staffs={data.staff}
            refetchMain={refetch}
          />
        )}
        <ViewInventryTransit
          show={viewItemModalShow}
          onHide={() => setViewItem(false)}
        />
      </main>
    </section>
  );
}

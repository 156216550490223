import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  getDataForEdit,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  BookIcon,
  RecieptIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase, truncate } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import {
  useEffectOnce,
  useScrollTop,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TransactionsTable from "../utils/TransactionsTable";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./../utils/ModalLoader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { IsPrivileged } from "../DisplayChildElement";

export default function ManageTransaction() {
  //  const itemOverlayRef = useRef();
  useScrollTop();
  const location = useLocation();
  const navigate = useNavigate();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const generalSettings = useStoreState((state) => state.generalSettings);

  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const [selectedSale, setSelectedSale] = useState({});

  useEffectOnce(() => {
    if (location.state && location.state?.TransactionID) {
      setTransactionID(location.state?.TransactionID);
      navigate(location.pathname, { replace: true });
    }
  });

  const deleteTransaction = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteTransactionMutation = useMutation(
    (payload) => deleteTransaction(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        discard();
        refetch();
        setTransactionID(null);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const [TransactionID, setTransactionID] = useState("");
  const formik = useFormik({
    initialValues: {
      date: moment(),
      Remark: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      // ---------
      deleteTransactionMutation.mutate({
        TransactionID,
        ...values,
        customer: data.customer,
      });
    },
    onReset: () => {},
  });

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(null);
  };

  const handleSelectedTransaction = (transaction) => {
    setTransactionID(transaction.TransactionID);
    setShowTransactionPopover(false);

    // fetch sales data
  };

  const getSales = async ({ TransactionID }) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/transaction/sales/${TransactionID}?withTransaction=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (!isEmpty(selectedSale) && !isEmpty(data?.sales) && TransactionID) {
      handleSelectedSale(
        data.sales.find((sale) =>
          sale?.ID
            ? selectedSale.ID === sale?.ID
            : selectedSale.Bar_Code === sale.Bar_Code &&
              sale.TransactionID === TransactionID
        )
      );
    }
    return data;
  };

  const transaction = useMemo(() => cloneDeep({ TransactionID }), [
    TransactionID,
  ]);

  const { data = { sales: [], deliveryInfo: {} }, refetch } = useQuery(
    [queryActions.GET_SALES_BY_TRANSACTION_ID, transaction],
    () => getSales(transaction),
    {
      keepPreviousData: false,
    }
  );

  const handleSelectedSale = (sale) => {
    setSelectedSale(sale);

    // get format type
    const { Tons, Pieces, formatType } = getDataForEdit({
      qty: sale?.QTY || 0,
      desc: sale?.Serial_Number,
      itemMeasurements,
    });

    // sale Type
    const saleType =
      Number(Tons) > 0
        ? "Tons"
        : Number(Pieces) > 0 && formatType !== "default"
        ? "Pieces"
        : "Each";

    formik.setValues({
      ...formik.values,
      Tons,
      Pieces,
      formatType,
      saleType,
      quantityToReturn: Number(Tons) > 0 ? Tons : Pieces,
      initialQuantityToReturn: Number(Tons) > 0 ? Tons : Pieces,
    });
  };

  return (
    <IsPrivileged roleName={["Manage Transaction"]}>
      <main className="create-invoice">
        <div className="content">
          <div className="d-md-flex content-holder rounded">
            <section
              /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
              className="item-details"
            >
              <div>
                <header className="h-auto">
                  <Form.Group className="mt-4 mb-3 col-md-6">
                    <InputGroup>
                      <Form.Control
                        className=""
                        type="text"
                        placeholder="Transaction ID"
                        name="TransactionID"
                        value={TransactionID}
                        onChange={(e) => setTransactionID(e.target.value)}
                      />

                      <Popover
                        isOpen={showTransactionPopover}
                        onClickOutside={() => setShowTransactionPopover(false)}
                        content={() => (
                          <TransactionsTable
                            handleSelectedTransaction={
                              handleSelectedTransaction
                            }
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() =>
                            setShowTransactionPopover(!showTransactionPopover)
                          }
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.Bar_Code}
                    </Form.Control.Feedback>
                  </Form.Group>
                </header>

                <div className="selected-data-area">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table  text-nowrap"
                    >
                      <thead>
                        <tr>
                          {generalSettings?.itemsRequiresSerialNumber ? (
                            <th>Serial Number</th>
                          ) : (
                            <th>Size/Desc</th>
                          )}
                          <th>Item Name</th>
                          <th>Price Sold</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                          <th>Discount</th>
                          <th>Item Code</th>
                          <th>Product name</th>
                          <th>Unit Price</th>
                          <th>Profit</th>
                          <th>...</th>
                          <th>...</th>
                          <th>Overwrite officer</th>
                          {/*  <th>Cost</th> */}
                          {/*  <th>Type</th> */}
                          <th>Unit Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.sales.map((el, index) => (
                          <tr key={index}>
                            {generalSettings?.itemsRequiresSerialNumber ? (
                              <td>{el?.SerialNumberItems}</td>
                            ) : (
                              <td>{truncate(el?.Serial_Number || "...")}</td>
                            )}
                            <td>{el.Item_Name}</td>
                            <td>
                              {currency(el.PriceSold, { symbol: "" }).format()}
                            </td>
                            <td>
                              {qtyFormatToString(
                                qtyFormat(
                                  el?.QTY,
                                  el?.Serial_Number,
                                  itemMeasurements
                                )
                              )}
                            </td>
                            <td>
                              {currency(el.SubTotal, { symbol: "" }).format()}
                            </td>
                            <td>
                              {currency(el.Discount, { symbol: "" }).format()}
                            </td>
                            <td>{el.Bar_Code}</td>
                            <td>{el.Product_Name || el.ProductName}</td>
                            <td>
                              {currency(el.Unit_Price, { symbol: "" }).format()}
                            </td>
                            <td>
                              {currency(el.Profit, { symbol: "" }).format()}
                            </td>
                            <td>{el.Warranty}</td>
                            <td>{el.Warrant_Duration}</td>
                            <td>{"..."}</td>
                            {/*  <td>
                            {currency(Number(el.UnitCost), { symbol: "" })
                              .multiply(
                                convertQuantity(
                                  el.Quantity,
                                  el.Serial_Number,
                                  el.saleType
                                )
                              )
                              .format()}
                          </td> */}
                            {/*  <td>{el.Item_Type || "..."}</td> */}
                            <td>
                              {currency(el.UnitCost, { symbol: "" }).format()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/*  */}

                  {/*  No item  */}
                  {isEmpty(data?.sales) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">No Items Found</h2>
                        <p>Please select a Transaction ID</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-end total-info ">
                <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Pos Charge</td>
                      <td>
                        {currency(data?.deliveryInfo?.PosCharge, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>LoadingCharge</td>
                      <td>
                        {currency(data?.deliveryInfo?.LoadingCharge, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Off Loading Charge</td>
                      <td>
                        {currency(data?.deliveryInfo?.OffloadingCharge, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Transport Charge</td>
                      <td>
                        {currency(data?.deliveryInfo?.TransportCharge, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Shipping Cost</td>
                      <td>
                        {currency(data?.deliveryInfo?.shippingCost, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Clearing charges, Custom duty <br /> and Other Charges
                      </td>
                      <td>
                        {currency(data?.deliveryInfo?.otherCharges, {
                          symbol: "₦",
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>{" "}
            </section>
            <section className="customer">
              <h2>Business Name</h2>

              <div className="d-flex justify-content-between">
                <div className="avatar">
                  <UserSolidIcon />
                </div>
                {data?.customer ? (
                  <div className="customer-actions d-flex justify-content-between flex-grow-1">
                    <div>
                      <h3>
                        {data?.customer?.LastName === "Walk-In"
                          ? `${data?.transaction?.ShipTo} (Walk-In)`
                          : data?.transaction?.ShipTo}
                      </h3>
                      <p>{data?.customer?.Cust_ID}</p>
                    </div>
                  </div>
                ) : (
                  <div className="customer-actions flex-grow-1">
                    <h3>No customer selected</h3>
                    <p>...</p>
                  </div>
                )}
              </div>

              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <section>
                  <h2 className="px-2">Payment Status</h2>
                  <Table borderless className="mb-5">
                    <tbody>
                      <tr>
                        <td className="fw-bold">Amount Due:</td>
                        <td>
                          {currency(data?.transaction?.Amount_Due, {
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Amount Paid:</td>
                        <td>
                          {currency(data?.transaction?.Amount_Paid, {
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Balance:</td>
                        <td>
                          {currency(data?.transaction?.Balance, {
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Prepared By:</td>
                        <td>{data?.transaction?.Username}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Date:</td>
                        <td>
                          {data?.transaction?.Trans_Date
                            ? format(
                                new Date(data?.transaction?.Trans_Date),
                                "dd MMM yyyy"
                              )
                            : "..."}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {/* <div className="px-2">
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction Remark</Form.Label>

                    <Form.Control
                      maxLength={500}
                      className=""
                      as="textarea"
                      placeholder="Enter Remark"
                      name="Remark"
                      value={formik.values.Remark}
                      onChange={formik.handleChange}
                      rows={5}
                    />
                  </Form.Group>
                </div> */}
                </section>

                {/*  <section className="/buttons">
                <Button
                  type="button"
                  variant="outline-primary"
                  // className="border-0"
                  onClick={() => discard()}
                >
                  Refresh
                </Button>
                <Button type="submit" variant="primary">
                  Delete Transaction
                </Button>
              </section> */}
              </Form>

              <section className="row flex-wrap gap-4 px-3">
                <Dropdown className="col px-0">
                  <Dropdown.Toggle
                    variant="outline-primary"
                    className="w-100 py-3"
                  >
                    Open Invoice
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-with-icons">
                    <Dropdown.Item
                      href={`${backendUrl}/api/invoice/pdf/invoice/${TransactionID}`}
                      target="blank"
                    >
                      <BookIcon />
                      A4 Size Printer
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={`${backendUrl}/api/invoice/pdf/invoice/${TransactionID}?thermalPrinter=true`}
                      target="blank"
                    >
                      <BookIcon />
                      Thermal Printer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="col px-0">
                  <Dropdown.Toggle
                    variant="outline-primary"
                    className="w-100 py-3"
                  >
                    Open Reciept
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-with-icons">
                    <Dropdown.Item
                      href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}`}
                      target="blank"
                    >
                      <RecieptIcon />
                      A4 Size Printer
                    </Dropdown.Item>
                    <Dropdown.Item
                      href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}?thermalPrinter=true`}
                      target="blank"
                    >
                      <RecieptIcon />
                      Thermal Printer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <a
                  href={`${backendUrl}/api/invoice/pdf/waybill/${TransactionID}`}
                  target="blank"
                  className="btn btn-primary p-3 col-12"
                  //  disabled={isEmpty(TransactionID)}
                >
                  Open Waybill
                </a>
              </section>
            </section>
          </div>
        </div>

        <ModalLoader show={deleteTransactionMutation.isLoading} />
      </main>
    </IsPrivileged>
  );
}
